import "./App.css";
import Game from "./Components/Game";
function App() {
  return (
    <div className="App">
      <p className="title">TIC TAC TOE GAME</p>
      <Game />
    </div>
  );
}

export default App;
